import React from "react";
import { Product } from "../../types/productTypes";
import { Input } from "../atomics/Input";
import { MdArrowForward } from "react-icons/md";
import Popup from "reactjs-popup";

interface EditableProductCardProps {
  title: string;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  productData?: Product;
  handleBlur?: () => void;
  isLoading?: boolean;
}

export const EditableProductCard: React.FC<EditableProductCardProps> = ({
  productData,
  title,
  setTitle,
  handleBlur,
  isLoading = false,
}) => {
  if (!productData) {
    return null;
  }

  return (
    <div className='w-full'>
      {!isLoading && (
        <>
          <Input
            name='title'
            label='Title'
            value={title}
            inputClassNames='w-full '
            onChange={(e) => setTitle(e.target.value)}
            onBlur={handleBlur}
          />
          <div className='pt-2 pb-2'>
            <span>Price: ${Math.ceil(productData.price)}</span>
            <span className='mx-4'>
              <MdArrowForward className='inline-block' />
            </span>
            <span>{productData.rewardCardPrice} Cards</span>
          </div>
          <Popup
            trigger={
              <div className='product-card__image-container mb-3 mt-3 flex justify-center'>
                <a
                  href={productData.link}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='flex items-center justify-center'
                >
                  <img
                    src={productData.imageUrl}
                    alt={`${productData.title}`}
                  />
                </a>
              </div>
            }
            position='center center'
            on='hover'
          >
            <a
              href={productData.link}
              target='_blank'
              rel='noopener noreferrer'
              className='flex items-center justify-center'
            >
              <div className='text-center'>View Product</div>
            </a>
          </Popup>
        </>
      )}
    </div>
  );
};
